window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(f){setTimeout(f, 1000/60)};

var midiaSize = (function() {

	verifyMidia();

	function verifyMidia () {
		var w = window.innerWidth;
		var size = 'xs';

		if(w < 500) {
			size = 'xs';
		}

		if(w >= 500) {
			size = 'sm';
		}

		if(w >= 768) {
			size = 'md';
		}

		if(w >= 992) {
			size = 'lg';
		}

		if(w >= 1200) {
			size = 'xl';
		}

		requestAnimationFrame(verifyMidia);

		return  midiaSize = size;
	}

})();


if (![].forEach) {
    Array.prototype.forEach = function (fn, scope) {
        'use strict'
        if(this === null) {
			throw new TypeError('this is null or not defined')
		}

		if(typeof fn !== 'function') {
			throw new TypeError(fn+' is not a function')
		}

        var i, len
        for (i = 0, len = this.length; i < len; ++i) {
            if (i in this) {
                fn.call(scope, this[i], i, this)
            }
        }
    }
}
