(function() {
	var topo      = document.querySelector('.topo');
	var faixaTopo = topo.querySelector('.faixa__topo');

	_check();

	function _check() {

		var offset  = faixaTopo.offsetHeight;

		topo.style.setProperty('--offset', parseInt(offset)+'px');

		if(window.scrollY >= offset) {
			topo.classList.add('topo--fixed');
		}else {
			topo.classList.remove('topo--fixed');
		}

		requestAnimationFrame(_check);
	}

})();
