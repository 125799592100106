(() => {
	const toggler = $('[data-toggle="search"]');
	const search  = $('.search');
	const active  = 'search--shown';
	const topo    = $('.topo');
	const body    = $('body');

	toggler.each(function() {
		const $this = $(this);

		if($this.hasClass('desktop-toggler')) {
			$this.btnRipple(toggleSearch.bind(this))
		}else {
			$this.click(toggleSearch);
		}

		function toggleSearch() {
			search.toggleClass(active);
			$(this).blur();

			body.addClass('backdrop__open');

			let bck = document.createElement('div');

			$(bck).addClass('backdrop')
				.click(function() {
					search.removeClass(active);
					body.removeClass('backdrop__open');

					$('.backdrop').fadeOut(600, function() {
						$(this).remove();
					});
				});

			topo.append(bck);
		}
	});

})();
