$.fn.mdSelect = function() {
	return this.each(function() {
		var select 		= $(this);
		var formGroup 	= select.parent('.md__form__group');
		var options     = this.querySelectorAll('option');

		formGroup.addClass('md__form__select--ready');
		formGroup.append(template(options));

		var drop   = formGroup.find('.dropdown-menu');
		var output = formGroup.find('.md__form__select__btn');

		$(options).each(function(index,option) {
			var li    = document.createElement('li');
			var value = option.getAttribute('value');
			var text  = option.innerHTML;

			li.innerHTML = text;

			if(value == '' || value == 'undefined' || value == 'null') {
				li.classList.add('null');
			}

			$(li).on('click', function() {
				output.text(text);
				select.val(value);

				$(li).siblings().removeClass('active');
				$(li).addClass('active');
			});

			drop.append(li);

		});

	});

	function template(options) {
		return `<div class="dropdown md__form__select__dropdown">
			<button class="md__form__select md__form__select__btn" data-toggle="dropdown">${options[0].innerHTML}</button>

			<ul class="dropdown-menu"></ul>
		</div>`;
	}
}

$('.md__form__group:not(.md__form__select--ready) select').mdSelect();
