(()=> {
	const btn     = $('[data-toggle="menu"]');
	const menu    = $('.main__topo');
	const body    = $('body');
	const topo    = $('.topo');
	const active  = 'main__topo--shown'
	const blur    = 'backdrop__open';

	btn.click(function(e) {
		e.preventDefault();

		menu.addClass(active);
		body.addClass(blur);

		let bck = document.createElement('div');

		$(bck).addClass('backdrop')
			.click(function() {
				menu.removeClass(active);
				body.removeClass('backdrop__open');

				$('.backdrop').fadeOut(600, function() {
					$(this).remove();
				});
			});

		topo.append(bck);
	});

})();
