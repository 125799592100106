(function() {
	$.fn.btnRipple = function(callback) {
		return this.each(function() {
			var $this = $(this);

			$this.click(function(e) {
				e.preventDefault();

				let ripple = document.createElement('div');

				$(ripple)
					.css({
						'--x': e.offsetX+'px',
						'--y': e.offsetY+'px'
					})
					.addClass('ripple')
					.on('animationend', function() {
						$(this).remove();

						if(callback) {
							callback();
						}

					});

				$this.append(ripple);
			});
		});
	}

	$('.btn-ripple').btnRipple();

	$('.btn-voltar').btnRipple(function() {
		history.back()
	});

	$('.btn-enviar').each(function() {
		var form = $(this).parents('form');

		$(this).btnRipple(function() {
			form.submit();
		});
	});
})();
