(function() {
	const observer = new IntersectionObserver(entries=> {
		entries.forEach(entry=> {
			if(entry.isIntersecting) {
				let animationClasses = entry.target.dataset.animate;

				animationClasses = animationClasses.split(' ');

				animationClasses.forEach(classe => entry.target.classList.add(classe));

				observer.unobserve(entry.target);
			}
		})
	});

	_check();

	function _check() {

		const animatedElements = document.querySelectorAll('[data-animate]:not(.animate__observe)');

		animatedElements.forEach(el => {
			el.classList.add('animate__observe');
			observer.observe(el)
		});

		requestAnimationFrame(_check);
	}
})();
