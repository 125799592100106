$.fn.typingAnimation = function() {
	var observer = new IntersectionObserver(entries=> {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				typeWriter(entry.target);

				observer.unobserve(entry.target);
			}
		});
	}, {
		treshold: 0.25
	});

	function typeWriter(el) {
		var i 	     = 0;
		var $this    = $(el);
		var text     = $(el).data('text');
		var interval = setInterval(write, 50);

		function write() {
			if(i < text.length) {
				el.innerHTML += text.charAt(i);
			}


			else {
				clearInterval(interval);

				if($this.data('next')) {
					$this.data('next')();
				}
			}

			i++;
		}
	}

	return this.each(function() {
		observer.observe(this);
	});
};
