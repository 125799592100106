(function() {
	var links    = $('.especialidade__thumb');
	var itens    = $('.especialidade__explicacao');
	var carousel = $('#owlEspecialidades');

	links.each(function(index) {
		var link   = $(this);
		var target = link.data('target');

		target = $(target);

		link.click(function(e) {
			e.preventDefault();

			itens.removeClass('active');
			links.removeClass('active');

			target.addClass('active');
			link.addClass('active');
		});
	});

})();
