(function() {
	var modal     = $('#modal-cookies');
	var cookies   = localStorage.getItem('usoDeCookies');
	var btn       = modal.find('.btn');

	if(!cookies) {
		modal.modal('show');

		btn.click(function() {
			localStorage.setItem('usoDeCookies', true);
			modal.modal('hide');
		});
	}
})();
