(function () {
	if(window.customElements) {
		class LazyImage extends HTMLElement{
			constructor(data) {
				super(data);
			}

			connectedCallback() {
				const self = this;
				const observer = new IntersectionObserver(function (entries) {
					for(var i =0; i<entries.length; i++) {
						var entry = entries[i];

						if(entry.isIntersecting) {
							let img   = document.createElement('img');
							let attrs = ['width', 'height', 'src', 'alt', 'srcset', 'sizes'];

							self.image = img;

							self.innerHTML = '';

							for(var a=0;a<attrs.length; a++) {
								var attr=attrs[a];

								if(self[attr]) {
									img.setAttribute(attr, self[attr]);
								}
							}

							img.addEventListener('load', event=> {
								self.classList.add('loaded');
								self.setAttribute('style', `--width:${img.naturalWidth};--height:${img.naturalHeight}`);
								self.width  = img.naturalWidth;
								self.height = img.naturalHeight;
							});

							img.addEventListener('error', event=> {
								self.classList.remove('loaded');
							});

							self.appendChild(img);

							observer.unobserve(self);
						}
					}
				}, {
					rootMargin: '200px'
				});

				observer.observe(self);

				if(!this.getAttribute('style')){
					let styles=`--width:${this.width};--height:${this.height};`;

					this.setAttribute('style', styles);
				}
			}

			get src () {
				return this.getAttribute('src');
			}

			set src (src) {
				return this.setAttribute('src', src);
			}

			get width () {
				return this.getAttribute('width');
			}

			set width (width) {
				return this.setAttribute('width', width);
			}

			get height () {
				return this.getAttribute('height');
			}

			set height (height) {
				return this.setAttribute('height', height);
			}

			get alt () {
				return this.getAttribute('alt');
			}

			set alt (alt) {
				return this.setAttribute('alt', alt);
			}

			get srcset () {
				return this.getAttribute('srcset');
			}

			set srcset (srcset) {
				return this.setAttribute('srcset', srcset);
			}

			get sizes () {
				return this.getAttribute('sizes');
			}

			set sizes (sizes) {
				return this.setAttribute('sizes', sizes);
			}

			static get observedAttributes() {
				return ['width', 'height', 'src', 'alt', 'srcset', 'sizes'];
			}

			attributeChangedCallback(name, oldValue, newValue) {
				var image = this.querySelector('img');

				if(image) {
					image.oldValue 		= image.oldValue || {};
					image.oldValue[name] 	= oldValue;

					image.setAttribute(name, newValue);
				}
			}
		}

		customElements.define('lazy-image', LazyImage);
	}else {
		window.addEventListener('load', ()=>{
			lazyImageFallback();
		});

		function lazyImageFallback() {
			var imgs = document.querySelectorAll('lazy-image');

			imgs.forEach(img=>{
				let attrs = img.attributes;
				let attrString = '';

				for(let i=0;i<attrs.length;i++) {
					let attr = attrs[i];

					attrString+= ` ${attr.name}="${attr.value}"`
				}

				img.insertAdjacentHTML('afterend', `<img ${attrString} class="img-fluid">`);
				img.parentNode.removeChild(img);
			});

			requestAnimationFrame(lazyImageFallback);
		}
	}
})();
