(function() {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(entry.isIntersecting) {

				entry.target.counter.start();

				observer.unobserve(entry.target);
			}
		});
	}, {
		treshold: 0.75
	});

	const els = document.querySelectorAll('[data-counter-up]');

	els.forEach(el => {
		el.counter = new CouterUpAnimation(el);

		observer.observe(el);
	});

	function CouterUpAnimation (element) {
		this.element  = element;
		this.range    = element.dataset.counterUp ? parseInt(element.dataset.counterUp) : 0;
		this.preffix  = element.dataset.preffix ? `<span class="preffix">${element.dataset.preffix}</span>` : '';
		this.suffix  = element.dataset.suffix ? `<span class="suffix">${element.dataset.suffix}</span>` : '';
		this.duration = element.dataset.duration ? parseInt(element.dataset.duration) : 2000;
		this.timer    = undefined;

		element.innerHTML = `${this.preffix}<span class="content">0</span>${this.suffix}`;
	}

	CouterUpAnimation.prototype.start = function() {
		var self      = this;
		var minTime  = 50;
		var stepTime = Math.abs(Math.floor(this.duration / this.range));

		stepTime = Math.max(stepTime, minTime);

		var startTime = new Date().getTime();
		var endTime   = startTime + self.duration;

		this.timer = setInterval(run, stepTime);

		function run () {
			var now = new Date().getTime();
			var remanecente = Math.max((endTime - now) / self.duration, 0);
			var valor = Math.round(self.range - (remanecente * self.range));

			self.element.innerHTML = `${self.preffix}<span class="content">${valor}</span>${self.suffix}`

			if(valor == self.range) {
				self.element.classList.add('counter--complete');
				clearInterval(self.timer);
			}
		}
	}

})();

(function() {
	var contatdoresSection = document.querySelector('.chamadas__contadores');

	if(contatdoresSection) {
		contatdoresSection.style.setProperty('--half', contatdoresSection.offsetHeight/-2+'px');
	}
})();
