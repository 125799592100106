(function() {
	var observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				var bg = JSON.stringify(entry.target.dataset.lazyBg);

				entry.target.style.setProperty('--lazy-bg', 'url('+bg+')');
				entry.target.removeAttribute('data-lazy-bg');

				observer.unobserve(entry.target);
			}
		});
	}, {
		rootMargin: '200px 200px 200px 200px'
	});

	$('[data-lazy-bg]').each(function() {
		observer.observe(this);
	});
})();
