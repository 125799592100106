(function() {
	var root 				= $('html');
	var fontToggler 		= $('[data-acessibilidade="A-"],[data-acessibilidade="A+"]');
	var contrasteToggler 	= $('[data-acessibilidade="contraste"]');

	var Acessibiliade = {
		fontState: {
			currentState: null,
			setState : function(value) {
				localStorage.setItem('fontState', value);
				Acessibiliade.currentState = value;
			},
			getState : function () {
				var currentState = localStorage.getItem('fontState');

				Acessibiliade.currentState = currentState;

				if(currentState === 'upper') {
					root.addClass('upper');
					$('[data-acessibilidade="A+"]').addClass('active');
				}else {
					root.removeClass('upper');
					$('[data-acessibilidade="A-"]').addClass('active');
					Acessibiliade.fontState.setState(null);
				}
			}
		},
		contrastState: {
			currentState: null,
			setState: function(value) {
				Acessibiliade.contrastState.currentState = value;
				localStorage.setItem('contrastState', value);
			},
			getState: function() {
				var currentState = localStorage.getItem('contrastState');

				if(currentState === 'true') {
					Acessibiliade.contrastState.currentState = true;
					contrasteToggler.attr('checked','');
					root.addClass('contraste');
				}else {
					Acessibiliade.contrastState.currentState = false;
					contrasteToggler.removeAttr('checked');
					root.removeClass('contraste');
				}
			}
		},
		check: function() {
			Acessibiliade.fontState.getState();
			Acessibiliade.contrastState.getState();
		}
	};

	Acessibiliade.check();

	contrasteToggler.change(function() {
		Acessibiliade.contrastState.setState(contrasteToggler.prop('checked'));

		if(contrasteToggler.prop('checked')) {
			root.addClass('contraste');
		}else {
			root.removeClass('contraste');
		}
	});

	fontToggler.each(function() {
		var $this = $(this);
		var type  = $(this).data('acessibilidade');

		switch (type) {
			case 'A-':
				$this.click(function() {
					set(null);
					root.removeClass('upper');
				})
			break;
			case 'A+':
				$this.click(function() {
					set('upper');
					root.addClass('upper');
				})
			break;
		}

		function set(value){
			Acessibiliade.fontState.setState(value);
			fontToggler.removeClass('active');
			$this.addClass('active').blur();
		}
	});
})();
